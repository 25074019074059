//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import { Modal } from '../../..';
import Trans from '../../../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../../../reducers/modal/modalActions';

// Styles
import '../tpwd-modal.scss';

const TPWD_Request_Receipt_Modal = () => {
  const dispatch = useDispatch();
  return (
    <Modal name="TPWD_Request_Receipt_Modal" className="tpwdas-modal">
      <h2 className="h1 modal-title-general">
        <Trans file="tpwd" id="RequestReceipt" />
      </h2>
      <p className="modal-description-general">
        <Trans file="tpwd" id="tpwd_Request_Receipt_Desc_One" />
      </p>
      <p className="modal-description-general">
        <Trans file="tpwd" id="tpwd_Request_Receipt_Desc_Two" />
      </p>
      <p className="modal-description-general-link">
        <Trans file="tpwd" id="tpwd_Request_Receipt_Desc_Three" />
      </p>
      <p className="modal-description-general">
        <Trans file="tpwd" id="tpwd_Request_Receipt_Desc_Four" />
      </p>

      <ButtonPrimary
        className="modal-close-button-general"
        onClick={() => dispatch(closeModal('TPWD_Request_Receipt_Modal'))}
      >
        <Trans file="Labels" id="gotIt" />
      </ButtonPrimary>
    </Modal>
  );
};

export default TPWD_Request_Receipt_Modal;
