//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import Modal from '../Modal';
import Trans from '../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './TPWDCantSelectServicesModal.scss';

const TPWDCantSelectServicesModal = () => {
  const dispatch = useDispatch();
  return (
    <Modal name="TPWDCantSelectServicesModal" className="cant-select-modal">
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="TPWDCantSelectServices_Title" />
      </h2>
      <div className="modal-content">
        <p className="modal-subtitle">
          <Trans file="Modals" id="TPWDCantSelectServices_Body_One" />
        </p>
        <p className="modal-subtitle">
          <Trans file="Modals" id="TPWDCantSelectServices_Body_Two" />
        </p>
        <ButtonPrimary
          className="modal-close-button"
          onClick={() => dispatch(closeModal('TPWDCantSelectServicesModal'))}
        >
          <Trans file="Labels" id="gotIt" />
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

export default TPWDCantSelectServicesModal;
