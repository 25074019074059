// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

//Requests
import { getLookUpDataAndRenewDMVRegistration } from '../../../reducers/agency/dmv/dmvActions';

// Types
import { AppParamsPropTypes } from '../../../components/common/Forms/LinkServiceLookupForm/LinkServiceLookupForm';

import DLRLinkServiceSuccess from '../../../agencies/DPS/link-service/LinkServiceSuccess';
import TDLRLinkServiceSuccess from '../../../agencies/TDLR/link-service/LinkServiceSuccess';
import DMVLinkServiceSuccess from '../../../agencies/DMV/link-service/LinkServiceSuccess';
import TPWDLinkServiceSuccess from '../../../agencies/TPWD/link-service/LinkServiceSuccess';

const LinkServiceSuccess = ({ link }) => {
  if (!link || !link.agency || !link.app || !link.success)
    return <Redirect to="/link" />;

  const { agency } = link;

  switch (agency.agencySlug) {
    case 'dps':
      return <DLRLinkServiceSuccess link={link} />;
    case 'dmv':
      return <DMVLinkServiceSuccess link={link} />;
    case 'tdlr':
      return <TDLRLinkServiceSuccess link={link} />;
    case 'tpwd':
      return <TPWDLinkServiceSuccess link={link} />;
    default:
      return '';
  }
};

const mapStateToProps = ({ agency: { link } }) => ({ link });
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLookUpDataAndRenewDMVRegistration,
    },
    dispatch
  );

LinkServiceSuccess.propTypes = {
  link: PropTypes.shape({
    params: PropTypes.arrayOf(AppParamsPropTypes),
    agency: PropTypes.object,
    app: PropTypes.object,
    linkData: PropTypes.object,
    success: PropTypes.object,
    relinkData: PropTypes.string,
  }).isRequired,
  getLookUpDataAndRenewDMVRegistration: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkServiceSuccess);
