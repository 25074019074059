//@flow

// Vendors
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import {
  Modal,
  FormActions,
  FormActionsItem,
  Trans,
  ServiceIconText,
} from '../..';
import { ButtonDanger, ButtonPrimary } from 'texkit/dist/components/Buttons';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';
import {
  unlinkAppLink,
  removeLinkFromState,
} from '../../../../reducers/agency/agencyActions';
import { storeReLinkServiceData } from '../../../../reducers/agency/linkService/linkServiceActions';
import {
  pushMessageBanner,
  setAutoDismissMessageBannerTimer,
} from '../../../../reducers/messageBanner/messageBannerActions';

// Styles
import './mismatch-modal.scss';

// Types
type Props = {
  modal: Object,
  agencies: Object,
  closeModal: Function,
  unlinkAppLink: Function,
  removeLinkFromState: Function,
};

const MismatchModal = ({
  unlinkAppLink,
  closeModal,
  removeLinkFromState,
  modal = {},
}: Props) => {
  if (
    modal.activeModal !== 'MismatchModal' ||
    !modal.data ||
    !modal.data.link ||
    !modal.data.agency
  )
    return null;

  const dispatch = useDispatch();
  const {
    data: { link, agency },
  } = modal;

  const hasLinks = agency.linkUserKeys && agency.linkUserKeys.length > 1;

  function handleRelinkingClick() {
    unlinkAppLink(link, modal.data.licenseNumber).then(() => {
      const id = link.profile.account[0].vin || null;
      dispatch(
        storeReLinkServiceData({ relinkData: id.substr(id.length - 4) })
      );
      dispatch(push('/link/lookup/dmvrr'));
    });
  }

  function handleRemoveLinkClick() {
    const messageIdTime = Date.now();

    const successBanner = hasLinks
      ? `${agency.agencySlug}_unlinkNotificationMessageBanner`
      : `${agency.agencySlug}_NoMoreAgencyNotificationsMessageBanner`;

    unlinkAppLink(link, modal.data.licenseNumber)
      .then(() => {
        dispatch(
          pushMessageBanner([
            {
              messageId: messageIdTime.toString(),
              textEnglish: `{"type": "Modals", "id": "${successBanner}"}`,
              textSpanish: `{"type": "Modals", "id": "${successBanner}"}`,
              startTime: '2021-01-01 18:35:00.0',
              endTime: '2040-06-30 12:05:00.0',
              priority: '4',
              status: 'R',
              author: 'USER',
              valid: true,
              dismissable: true,
            },
          ])
        );
        if (!hasLinks) dispatch(push('/dashboard'));
        closeModal('MismatchModal');
        setTimeout(() => removeLinkFromState(link), 200);
        setAutoDismissMessageBannerTimer(messageIdTime);
      })
      .catch(() => {
        dispatch(
          pushMessageBanner([
            {
              messageId: messageIdTime.toString(),
              textEnglish: `{"type": "Modals", "id": "${agency.agencySlug}_cantUnlink"}`,
              textSpanish: `{"type": "Modals", "id": "${agency.agencySlug}_cantUnlink"}`,
              startTime: '2021-01-01 18:35:00.0',
              endTime: '2040-06-30 12:05:00.0',
              priority: '1',
              status: 'R',
              author: 'USER',
              valid: true,
              dismissable: true,
            },
          ])
        );
        closeModal('MismatchModal');
      });
  }

  return (
    <Modal
      name="MismatchModal"
      className="mismatch-service-modal"
      disableOnClickOutside={true}
    >
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="SomethingsWrong" />
      </h2>
      <div className="modal-content">
        <p className="lead">
          <Trans file="Modals" id="VehicleMismatch" />
        </p>
        <div className="mismatch-service-modal-list">
          {link.profile.account.map(vehicle => (
            <SummaryCard
              key={vehicle.plateNumber}
              className="card-flat"
              outlined={false}
            >
              <div className="flex-item">
                <div className="flex-row-wrap">
                  <div className="flex-item license-plate-icon">
                    <ServiceIconText
                      className="agency-linked-services-item-icon-text flex-item"
                      type={`license-plate`}
                      text={vehicle.plateNumber}
                    />
                  </div>
                  <div className="flex-item vehicle-details">
                    <SummaryCard.List
                      items={[
                        {
                          label: <Trans file="Labels" id="plateNumber" />,
                          value: vehicle.plateNumber,
                        },
                        {
                          label: <Trans file="Labels" id="lastFourDigitsVIN" />,
                          value: vehicle.vin.slice(-4),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </SummaryCard>
          ))}
        </div>
        <FormActions align="center">
          {/* TODO: Make link below dynamic */}
          <FormActionsItem>
          <ButtonDanger className="ButtonDanger"
              onClick={handleRemoveLinkClick}
              loadingContent={<Trans file="Labels" id="Removing" />}
            >
              <Trans file="Labels" id="RemoveLink" />
            </ButtonDanger>
          </FormActionsItem>
          <FormActionsItem>
          <ButtonPrimary onClick={handleRelinkingClick} className="ButtonPrimary">
              <Trans file="Labels" id="UpdateInformation" as="Link" />
            </ButtonPrimary>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators(
    { closeModal, unlinkAppLink, removeLinkFromState },
    dispatch
  );
const mapStateToProps = ({ modal }): Object => ({ modal });

export default connect(mapStateToProps, mapDispatchToProps)(MismatchModal);
