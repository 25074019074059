/* eslint-disable no-irregular-whitespace */
//@flow
import React from 'react';
import states from '../data/states';
import countries from '../data/countries';
import { toSentenceCase, toTitleCase } from './';

type Address = {
  streetAddress: string,
  addressLine2?: string,
  addressLine3?: string,
  city?: string,
  state?: string,
  zipFive?: string,
  zipFour?: string,
  postalCode?: string,
  county?: string,
  country?: string,
  verifySkip?: boolean,
  addressType?: string,
  addressLocation?: string,
};
type ForeignAddress = {
  streetAddress: string,
  addressLine2?: string,
  addressLine3?: string,
  city: string,
  state?: string,
  county?: string,
  postalCode?: string,
  county: { label?: string, code: string },
  country: { label?: string, code: string },
};
type AddressForForm = {
  addressLocation: string,
  streetAddress: string,
  addressLine2?: string,
  addressLine3?: string,
  city: string,
  state: { label?: string, value: string },
  county: { label?: string, value: string },
  zipFive: string,
  zipFour?: string,
  foreignAddress: ForeignAddress,
  verifySkip?: boolean,
};

function trimEmptyStrings(address: Address) {
  const trimmedAddress = { ...address };
  Object.keys(trimmedAddress).forEach(key => {
    if (typeof trimmedAddress[key] === 'string') {
      trimmedAddress[key] = trimmedAddress[key].trim();
    }
  });
  return trimmedAddress;
}

export function isCompleteAddress(address: Address) {
  if (!address) return false;
  const { streetAddress, city, state, zipFive } = address;
  return streetAddress && city && state && zipFive;
}

export function formatAddress({
  streetAddress,
  addressLine2,
  addressLine3,
  city,
  state,
  zipFive,
  zipFour,
  postalCode,
  county,
  country,
}: Address) {
  let address = streetAddress;
  if (addressLine2) address += ` ${addressLine2}`;
  if (addressLine3) address += ` ${addressLine3}`;
  if (city) address += ` ${city},`;
  if (state) address += ` ${state},`;
  if (zipFive) address += ` ${zipFive}`;
  if (zipFour) address += `-${zipFour},`;
  if (postalCode) address += ` ${postalCode},`;
  if (county) address += ` ${county},`;
  if (country) address += ` ${country}`;
  return address;
}

export function formatMultiLineAddress({
  streetAddress,
  addressLine2,
  addressLine3,
  city,
  state,
  postalCode,
  county,
  country,
  zipFive,
  zipFour,
}: Address) {
  let firstLine = streetAddress;
  let secondLine = '';
  let thirdLine = '';
  if (country) {
    if (addressLine2) secondLine += `${addressLine2}`;
    if (addressLine3) secondLine += `${addressLine3}`;
    if (city) thirdLine += `${city}, `;
    if (state) thirdLine += ` ${state} `;
    if (postalCode) thirdLine += ` ${postalCode},`;
    if (zipFive) thirdLine += ` ${zipFive}`;
    if (zipFour) thirdLine += `-${zipFour} `;
    if (county) thirdLine += ` ${county}, `;
    if (country) thirdLine += ` ${country}`;
  } else {
    if (addressLine2) firstLine += ` ${addressLine2}`;
    if (city) secondLine += `${city}, `;
    if (state) secondLine += ` ${state} `;
    if (zipFive) secondLine += ` ${zipFive}`;
    if (zipFour) secondLine += `-${zipFour}`;
    if (county) secondLine += ` ${county}`;
  }
  return (
    <>
      <>
        {firstLine}
        <br />
      </>
      {secondLine && (
        <>
          {secondLine} <br />
        </>
      )}
      {thirdLine}
    </>
  );
}

export function formatAddressForVerifyAddressModal(address: Address) {
  const trimmedAddress = trimEmptyStrings(address);
  const {
    streetAddress,
    addressLine2,
    addressLine3,
    city,
    state,
    postalCode,
    country,
    zipFive,
    zipFour,
  } = trimmedAddress;

  let firstLine = streetAddress;
  let secondLine = '';
  let thirdLine = '';
  let fourthLine = '';

  if (addressLine2) secondLine += `${addressLine2}`;
  if (addressLine3) secondLine += `${addressLine3}`;
  if (city) thirdLine += `${city}, `;
  if (state) thirdLine += `${state} `;
  if (postalCode) thirdLine += `${postalCode},`;
  if (zipFive) thirdLine += `${zipFive}`;
  if (zipFour) thirdLine += `-${zipFour} `;
  if (country) fourthLine += `${country}`;

  return (
    <>
      {firstLine && <div>{firstLine}</div>}
      {secondLine && <div>{secondLine}</div>}
      {thirdLine && <div>{thirdLine}</div>}
      {fourthLine && <div>{fourthLine}</div>}
    </>
  );
}

// formatMultiLineAddressForDPS
export function formatMultiLineAddressForDPS({
  streetAddress,
  addressLine2,
  addressLine3,
  city,
  state,
  postalCode,
  county,
  country,
  zipFive,
  zipFour,
}: Address) {
  let firstLine = streetAddress;
  let secondLine = '';
  let thirdLine = '';
  if (country) {
    if (addressLine2) secondLine += `${addressLine2}`;
    if (addressLine3) secondLine += `${addressLine3}`;
    if (city) thirdLine += `${city}, `;
    if (state) thirdLine += ` ${state} `;
    if (postalCode) thirdLine += ` ${postalCode}`;
    if (zipFive) thirdLine += ` ${zipFive}`;
    if (zipFour) thirdLine += `-${zipFour} `;
    if (county) thirdLine += ` ${county}, `;
    if (country) thirdLine += ` ${country}`;
  } else {
    if (addressLine2) firstLine += ` ${addressLine2}`;
    if (city) thirdLine += `${city}, `;
    if (state) thirdLine += ` ${state} `;
    if (zipFive) thirdLine += ` ${zipFive}`;
    if (zipFour) thirdLine += `-${zipFour}`;
    if (county) thirdLine += ` ${county}`;
  }
  return (
    <>
      <>
        {firstLine} {secondLine ? secondLine : ''}
        <br />
      </>
      {thirdLine}
    </>
  );
}

// Melissa Verified Address Format
export function melissaAddressFormatForDPS({
  streetAddress,
  addressLine2,
  addressLine3,
  city,
  state,
  zipFive,
  county,
}: Address) {
  let firstLine = streetAddress;
  let secondLine = '';
  let thirdLine = '';
  if (addressLine2) secondLine += `${addressLine2}`;
  if (addressLine3) secondLine += `${addressLine3}`;
  if (city) thirdLine += `${city}, `;
  if (state) thirdLine += ` ${state} `;
  if (zipFive) thirdLine += ` ${zipFive}`;
  if (county) thirdLine += ` ${county}`;
  return (
    <>
      <>
        {firstLine} {secondLine ? secondLine : ''}
        <br />
      </>
      {thirdLine}
    </>
  );
}

export const addressIsEqual = (address1: Object, address2: Object) =>
  formatAddress(address1).toLowerCase() ===
  formatAddress(address2).toLowerCase();

export const shouldVerifyAddress: ({
  address: Address,
}) => boolean = (formValues: { address: Address }) => {
  if (formValues.address.verifySkip === true) {
    return false;
  }

  if (formValues.address.country && formValues.address.country !== '') {
    return false;
  }

  return true;
};

export const formatAddressForForm: Address => AddressForForm = (
  address: Address
) => {
  address.addressLocation =
    address.country && address.country !== 'US' && address.country !== 'USA'
      ? 'foreign'
      : 'domestic';
  if (
    address.country &&
    address.country !== 'US' &&
    address.country !== 'USA'
  ) {
    const country = countries.find(country => country.code === address.country);
    const foreignAddress = {
      streetAddress: address.streetAddress,
      addressLine2: address.addressLine2,
      addressLine3: address.addressLine3,
      city: address.city || '',
      state: address.state || '',
      postalCode: address.postalCode || address.zipFive || '',
      country: country || { code: '' },
    };
    return {
      addressLocation: 'foreign',
      streetAddress: '',
      addressLine2: '',
      city: '',
      state: { label: 'Texas', value: 'TX' },
      zipFive: '',
      foreignAddress,
      verifySkip: address.verifySkip,
      addressType: address.addressType,
    };
  }

  // $FlowIgnore - sometimes we get state as an object
  let state: { label?: string, value: string } = address.state;
  if (typeof address.state === 'string') {
    state = address.state
      ? states.find(state => state.value === address.state) || { value: '' }
      : { value: 'TX' };
  }

  return {
    addressLocation: 'domestic',
    streetAddress: address.streetAddress,
    addressLine2: address.addressLine2,
    city: address.city || '',
    state: state,
    zipFive: address.zipFive || '',
    zipFour: address.zipFour,
    foreignAddress: {
      streetAddress: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      state: '',
      postalCode: '',
      country: { code: '' },
    },
    verifySkip: address.verifySkip,
    addressType: address.addressType,
  };
};
export const formatAddressFromForm: AddressForForm => Address = (
  address: Object
) => {
  if (
    typeof address.country === 'string' &&
    typeof address.county === 'string' &&
    address.addressLocation === 'foreign'
  )
    return address; // address is already formatted
  if (
    address.addressLocation === 'foreign' &&
    address.foreignAddress.country.code !== ''
  )
    return {
      ...address.foreignAddress,
      addressLocation: address.addressLocation,
      country: address.foreignAddress.country.code,
    };
  if (address.state && address.state.value)
    address.state =
      typeof address.state === 'string' ? address.state : address.state.value;
  if (address.county && address.county.value)
    address.county =
      typeof address.county === 'string'
        ? address.county
        : address.county.label;
  delete address.addressLine3;
  delete address.postalCode;
  delete address.country;
  delete address.foreignAddress;
  delete address.email;
  delete address.phoneNumber;
  return { ...address };
};

// For DLR
export const formatAddressFormForDPS: AddressForForm => Address = (
  address: Object
) => {
  if (
    typeof address.country === 'string' &&
    address.addressLocation === 'foreign'
  )
    return address;
  if (
    address.addressLocation === 'foreign' &&
    address.foreignAddress.country.code !== ''
  )
    return {
      ...address.foreignAddress,
      addressLocation: address.addressLocation,
      country: address.foreignAddress.country.code,
    };

  if (address.state && address.state.value)
    address.state =
      typeof address.state === 'string' ? address.state : address.state.value;

  if (address.county && address.county.label)
    address.county =
      typeof address.county === 'string'
        ? address.county
        : address.county.label;

  if (address.country && address.country.code)
    address.country =
      typeof address.country === 'string'
        ? address.country
        : address.country.code;

  delete address.addressLine3;
  delete address.postalCode;
  delete address.foreignAddress;
  return { ...address };
};


// Address Format for TPWD
export function addressFormatTPWD(fName, lName, {
  streetAddress,
  address1,
  address2,
  city,
  stateProvince,
  postalCode
}: Address) {
  let firstLine = `${fName} ${lName}`;
  let secondLine = '';
  let thirdLine = '';
  if (address1) secondLine += toTitleCase(address1);
  if (address2) secondLine += toTitleCase(address1);
  if (city) thirdLine += `${toTitleCase(city)}, `;
  if (stateProvince) thirdLine += ` ${stateProvince} `;
  if (postalCode) thirdLine += ` ${postalCode}`;
  return (
    <>
      <>
        {firstLine}
        <br />
        {secondLine ? secondLine : ''}
        <br />
      </>
      {thirdLine}
    </>
  );
}