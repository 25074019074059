export const tpwd = {
  APP_ERRORS: {
    tpwdas: {
      linkService: {
        txNumber: 'params.txNumber',
        dob: 'params.dob',
        lastName: 'params.lastName',
      },
    },
  },
};
