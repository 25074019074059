//@flow

// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// // Helpers
import { publicURL } from '../../../../lib/helpers';

// Components
import Modal from '../Modal';
import Trans from '../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

//Selectors
import { selectSelectedLanguage } from '../../../../reducers/language/languageSelectors';

// Styles
import './TPWDTXNumberModal.scss';

const TPWDTXNumberModal = () => {
  const dispatch = useDispatch();
  let language = useSelector(selectSelectedLanguage).toLowerCase();
  return (
    <Modal name="TPWDTXNumberModal" className="tx-number-modal">
      <h2 className="h1 modal-title">
        <Trans
          file="Modals"
          id="TPWDTXNumberModal_Title"
          fallback="Find your TX number"
        />
      </h2>
      <div className="modal-content">
        <p className="modal-subtitle">
          <Trans
            file="Modals"
            id="TPWDTXNumberModal_Body"
            fallback="The TX Number can be found on the registration renewal notice, the certificate of number card or the title. The number consists of four numbers followed by two letters. (Example: 1234AA)."
          />
        </p>
        <div className="modal-graphic">
          {language === 'en' ? <img src={publicURL(`tx-number-example.png`)} /> : <img src={publicURL(`tx-number-example-es.png`)} />}
        </div>
        <ButtonPrimary
          className="modal-close-button"
          onClick={() => dispatch(closeModal('TPWDTXNumberModal'))}
        >
          <Trans file="Labels" id="gotIt" />
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

export default TPWDTXNumberModal;
