import ERRORS from './errorCopy';

const options = {
  FORM_HELP: ref => ({
    ID: 'FORM_HELP',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  CREATE_ACCOUNT_ERROR: ref => ({
    ID: 'CREATE_ACCOUNT_ERROR',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.CREATE_ACCOUNT_EXCEPTION(ref),
    },
  }),
  STEPUP_CONFIRMATION_ERROR: () => ({
    ID: 'STEPUP_CONFIRMATION_EXCEPTION',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.STEPUP_CONFIRMATION_EXCEPTION,
    },
  }),
  MGMW_VE_0000: () => ({
    ID: 'REQUIRED',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.REQUIRED,
      currentPassword: ERRORS.FIELDS.PASSWORD.REQUIRED,
      newPassword: ERRORS.FIELDS.NEW_PASSWORD.REQUIRED,
      password: ERRORS.FIELDS.PASSWORD.REQUIRED,
      email: ERRORS.FIELDS.EMAIL.REQUIRED,
      phone: ERRORS.FIELDS.PHONE.VALID,
      MFAOption: ERRORS.FIELDS.MFA_OPTIONS.REQUIRED,
      code: ERRORS.FIELDS.CONTACT_CODE.REQUIRED,
      fName: ERRORS.FIELDS.FIRST_NAME.REQUIRED,
      lName: ERRORS.FIELDS.LAST_NAME.REQUIRED,
      cappsId: ERRORS.FIELDS.CAPPSID.REQUIRED,
      dob: ERRORS.FIELDS.DOB.REQUIRED,
      lastFourSSN: ERRORS.FIELDS.LAST_FOUR_SSN.REQUIRED,
      streetAddress: ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.REQUIRED,
      terms: true,
      'question1.quid': ERRORS.FIELDS.SECURITY_QUESTIONS.QUESTION.REQUIRED,
      answer1: ERRORS.FIELDS.SECURITY_QUESTIONS.ANSWER.REQUIRED,
      'question2.quid': ERRORS.FIELDS.SECURITY_QUESTIONS.QUESTION.REQUIRED,
      answer2: ERRORS.FIELDS.SECURITY_QUESTIONS.ANSWER.REQUIRED,
      'question3.quid': ERRORS.FIELDS.SECURITY_QUESTIONS.QUESTION.REQUIRED,
      answer3: ERRORS.FIELDS.SECURITY_QUESTIONS.ANSWER.REQUIRED,
    },
  }),
  MGMW_VE_0001: () => ({
    ID: 'TOO_SHORT',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.MIN,
    },
  }),
  MGMW_VE_0002: () => ({
    ID: 'TOO_LONG',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.MAX,
    },
  }),
  MGMW_VE_0003: () => ({
    ID: 'INVALID_CHARS',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.INVALID_CHARS,
    },
  }),
  MGMW_VE_0004: () => ({
    ID: 'BAD_DATA',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.SORRY,
      addressLine2: ERRORS.FIELDS.ADDRESS.ADDRESS_LINE_2.INVALID,
    },
  }),
  MGMW_VE_0005: () => ({
    ID: 'ALNUM',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.ALPHANUMERIC_ONLY,
    },
  }),
  MGMW_VE_0006: () => ({
    ID: 'ALPHABET',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.ALPHABETICAL_ONLY,
    },
  }),
  MGMW_VE_0007: () => ({
    ID: 'NO_SPEC_CHAR',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.NO_SPEC_CHAR,
    },
  }),
  MGMW_VE_0008: () => ({
    ID: 'REQ_DL_ADDR',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.REQ_DL_ADDR,
    },
  }),
  MGMW_VE_0009: () => ({
    ID: 'FORMAT',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.FORMAT,
      email: ERRORS.FIELDS.EMAIL.INVALID,
      streetAddress: ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.REQUIRED,
    },
  }),
  MGMW_VE_0010: () => ({
    ID: 'NUMBER',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.NUMERIC_ONLY,
    },
  }),
  MGMW_VE_0011: () => ({
    ID: 'CAPPSID',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.CAPPSID,
    },
  }),
  MGMW_VE_1001: () => ({
    ID: 'NAME_FORMAT',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.ALPHABETICAL_ONLY,
    },
  }),
  MGMW_VE_1002: () => ({
    ID: 'UNDER_EIGHTEEN',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.DOB.MAX,
    },
  }),
  MGMW_VE_1003: () => ({
    ID: 'EMAIL_FORMAT',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.EMAIL.INVALID,
    },
  }),
  MGMW_VE_9001: (ref, arg) => ({
    ID: 'MIN_LENGTH',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.MIN_LENGTH(arg),
      currentPassword: ERRORS.FIELDS.PASSWORD.MIN,
      newPassword: ERRORS.FIELDS.PASSWORD.MIN,
      answer1: ERRORS.FIELDS.SECURITY_QUESTIONS.ANSWER.MIN,
      answer2: ERRORS.FIELDS.SECURITY_QUESTIONS.ANSWER.MIN,
      answer3: ERRORS.FIELDS.SECURITY_QUESTIONS.ANSWER.MIN,
    },
  }),
  MGMW_VE_9002: (ref, arg) => ({
    ID: 'MAX_LENGTH',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.MAX_LENGTH(arg),
      currentPassword: ERRORS.FIELDS.PASSWORD.MAX,
      newPassword: ERRORS.FIELDS.PASSWORD.MAX,
    },
  }),
  MGMW_VE_9003: (ref, arg) => ({
    ID: 'SPEC_CHAR',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.SPEC_CHAR(arg),
    },
  }),
  MGMW_VE_9004: (ref, arg) => ({
    ID: 'DIGITS',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.DIGITS(arg),
      zipFive: ERRORS.FIELDS.ADDRESS.ZIP_FIVE.DIGITS,
      code: ERRORS.FIELDS.CONTACT_CODE.MIN,
    },
  }),
  MGMW_VE_9005: () => ({
    ID: 'DATE_FORMAT',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.DATE.FORMAT,
    },
  }),
  MGMW_VE_9006: (ref, arg) => ({
    ID: 'ALLOWED_LIST',
    TEXT: {
      DEFAULT: ERRORS.FIELDS.ALLOWED_LIST(arg),
      'state.value': ERRORS.FIELDS.ADDRESS.STATE.REQUIRED,
    },
  }),
  MGMW_VE_9007: ref => ({
    ID: 'MISMATCH',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_VE_9008: ref => ({
    ID: 'EXPECTED_QTY',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_E_0404: () => ({
    ID: 'USER_NF',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.USER_NF,
    },
  }),
  MGMW_E_1404: () => ({
    ID: 'CONTACT_NF',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.CONTACT_NF,
    },
  }),
  MGMW_E_2404: () => ({
    ID: 'SUBSCRIPTION_NF',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.SUBSCRIPTION_NF,
    },
  }),
  MGMW_E_3404: () => ({
    ID: 'ADDRESS_NF',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.ADDRESS_NF,
    },
  }),
  MGMW_E_4404: () => ({
    ID: 'TRANSACTION_NF',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.TRANSACTION_NF,
    },
  }),
  MGMW_E_5404: () => ({
    ID: 'QUESTION_NF',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.QUESTION_NF,
    },
  }),
  MGMW_E_0001: () => ({
    ID: 'USER_DEACTIVATED',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.USER_DEACTIVATED,
    },
  }),
  MGMW_E_0002: () => ({
    ID: 'USER_EXISTS',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.USER_EXISTS,
    },
  }),
  MGMW_E_0003: () => ({
    ID: 'USER_RECORD_EXISTS',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.USER_RECORD_EXISTS,
    },
  }),
  MGMW_E_0004: () => ({
    ID: 'USER_TOAS_FAIL',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.USER_TOAS_FAIL,
    },
  }),
  MGMW_E_0005: () => ({
    ID: 'USER_LOCKED',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.USER_LOCKED,
    },
  }),
  MGMW_E_0006: () => ({
    ID: 'USER_EXCEED_RESET',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.USER_EXCEED_RESET,
    },
  }),
  MGMW_E_0007: () => ({
    ID: 'USER_IDOLOGY_FAIL',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.USER_IDOLOGY_FAIL,
    },
  }),
  MGMW_E_0008: (ref, arg) => ({
    ID: 'IDOLOGY_FAIL_ERROR',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.IDOLOGY_FAIL_ERROR(arg),
    },
  }),
  MGMW_E_0009: () => ({
    ID: 'USER_BLACKLISTED',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.USER_BLACKLISTED,
    },
  }),
  MGMW_E_0013: () => ({
    ID: 'WORK_EMAIL_EXISTS',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.USER_WORK_EMAIL_IN_USE,
    },
  }),
  MGMW_E_1001: () => ({
    ID: 'CONTACT_ADDRESS_INVALID',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT_REFRESH,
    },
  }),
  MGMW_E_1002: () => ({
    ID: 'CONTACT_NOT_VERIFIED',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.CONTACT_NOT_VERIFIED,
    },
  }),
  MGMW_E_5001: () => ({
    ID: 'QUESTION_DUPE',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.QUESTION_DUPE,
    },
  }),
  MGMW_E_5100: () => ({
    ID: 'INCORRECT',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.INCORRECT_ANSWERS,
    },
  }),
  MGMW_E_25001: () => ({
    ID: 'OTP_MESSAGE_TYPE',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT_REFRESH,
    },
  }),
  MGMW_E_25002: () => ({
    ID: 'OTP_VERIFY',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.OTP_VERIFY,
    },
  }),
  MGMW_E_25003: ref => ({
    ID: 'OTP_CANNOT_SEND',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_E_25004: (ref, arg) => ({
    ID: 'OTP_ADDRESS_FAIL',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.OTP_ADDRESS_FAIL(arg),
    },
  }),
  MGMW_E_25005: () => ({
    ID: 'OTP_EXPIRED',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.OTP_EXPIRED,
    },
  }),
  MGMW_E_25101: ref => ({
    ID: 'MESSAGE_CHANNEL_FAIL',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_E_25102: ref => ({
    ID: 'MESSAGE_TEMPLATE',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_E_25103: () => ({
    ID: 'MESSAGE_EXCEEDED',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.MESSAGE_EXCEEDED,
    },
  }),
  // MGMW_E_3102 - used when a payment occurs twice in the payment flow
  MGMW_E_99001: ref => ({
    ID: 'VERSIONID',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_E_99002: ref => ({
    ID: 'VERSIONID',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_EX_0000: ref => ({
    ID: 'UNKNOWN_EXCEPTION',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),

  MGMW_EX_0001: ref => ({
    ID: 'STALE_INPUT',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_EX_0002: ref => ({
    ID: 'FORGE_ROCK',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_EX_0003: ref => ({
    ID: 'HTTP',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_EX_1001: () => ({
    ID: 'INVALID_CREDENTIALS',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.INVALID_CREDENTIALS,
    },
  }),
  MGMW_EX_1002: () => ({
    ID: 'PASSWORD_HISTORY',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.PASSWORD_HISTORY,
    },
  }),
  // TODO: This is just a temporary code. We need BE to provide more specific error codes
  // for these flows, based on the app that is being linked.
  MGMW_EX_0013: () => ({
    ID: 'VERIFY_APP_PROFILE_FAIL',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.VERIFY_APP_PROFILE_FAIL,
    },
  }),
  MGMW_EX_3007: () => ({
    ID: 'PROFILE_NOT_FOUND',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.PROFILE_NF,
    },
  }),
  MGMW_EX_5001: () => ({
    ID: 'PROFILE_NOT_FOUND',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.AGENCY_NF,
    },
  }),
  MGMW_EX_5002: () => ({
    ID: 'APPLICATION_NOT_FOUND',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.APPLICATION_NF,
    },
  }),
  //EX_5000 is seen when IDM is down
  //have to manage it differently than AGENCY_NF
  // MGMW_EX_5000: () => ({
  //   ID: 'PROFILE_NOT_FOUND',
  //   TEXT: {
  //     DEFAULT: ERRORS.EXCEPTIONS.AGENCY_NF
  //   }
  // }),
  MGMW_EX_3008: () => ({
    ID: 'PAYMENT_FAILED',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.PAYMENT_FAILED,
    },
  }),
  MGMW_EX_3013: () => ({
    ID: 'VEHICLE_NOT_FOUND',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.VEHICLE_NF,
    },
  }),
  MGMW_EX_3108: () => ({
    ID: 'PAYMENT_ATTRIBUTE_FAILURE',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.PAYMENT_FAILED,
    },
  }),
  MGMW_EX_3501: () => ({
    ID: 'VEHICLE_NOT_FOUND',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.VEHICLE_NF,
    },
  }),
  MGMW_EX_4000: ref => ({
    ID: 'BAD_REQUEST',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_EX_4001: ref => ({
    ID: '',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_EX_4002: ref => ({
    ID: 'UNAUTHORIZED',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.DEFAULT(ref),
    },
  }),
  MGMW_EX_3901: () => ({
    ID: 'ASSET_NOT_FOUND',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.ASSET_NF,
    },
  }),
  MGMW_EX_3902: () => ({
    ID: 'ASSET_VERIFY_LOCKED',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.ASSET_VERIFY_LOCKED,
    },
  }),
  MGMW_EX_4005: ref => ({
    ID: 'TPWD_BAD_REQUEST',
    TEXT: {
      DEFAULT: ERRORS.EXCEPTIONS.ASSET_LINK_EXCEPTION,
    },
  }),
};

export function getServerErrorText({
  fieldName,
  errorCode,
  errorArgs,
  errorRef,
}) {
  if (!options[errorCode]) return errorCode;
  const error = options[errorCode](errorRef, errorArgs);
  return error.TEXT[fieldName] || error.TEXT.DEFAULT;
}
