/* eslint-disable flowtype/no-types-missing-file-annotation */
// Vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { goBack } from 'connected-react-router';

// Actions
import {
  linkAppLink,
  linkServiceSuccess,
} from '../../../reducers/agency/linkService/linkServiceActions';

// Components
import { FormActions, FormActionsItem, Trans } from '../../../components';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import {
  TxAlert,
  TxAlertType,
  TxButton,
  TxButtonMode,
} from 'texkit-ui/components';

// Helpers
import { scrollToTop, toSentenceCase } from '../../../lib/helpers';

// Styles
import './link-service-confirm.scss';

const TPWDLinkServiceConfirm = ({
  txNumber,
  assetType,
  hullMaterial,
  modelYear,
  make,
  length,
  expirationDate,
}) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState(false);

  const handleConfirmClick = () => {
    setSubmitting(true);
    dispatch(linkAppLink())
      .then(() => {
        setError(false);
        dispatch(linkServiceSuccess("tpwdas"));
      })
      .catch(() => {
        setError(true);
        scrollToTop();
        setSubmitting(false);
      });
  };

  return (
    <div className="tpwd-link-service-confirm">
      <h1 className="h2">
        <Trans file="tpwd" id="tpwdas_LinkService_ConfirmTitleDefault" />
      </h1>
      <p>
        <Trans file="tpwd" id="tpwdas_LinkService_ConfirmDescriptionDefault" />
      </p>

      <>
        <SummaryCard>
          <SummaryCard.List
            items={[
              {
                className: 'word-break',
                label: <Trans file="Labels" id="txNumber" />,
                value: txNumber,
              },
              {
                label: <Trans file="Labels" id="assetType" />,
                value: toSentenceCase(assetType),
              },
              {
                label: <Trans file="Labels" id="assetModelYear" />,
                value: modelYear,
              },
              {
                label: <Trans file="Labels" id="assetMake" />,
                value: make,
              },
              {
                label: <Trans file="Labels" id="assetHullType" />,
                value: toSentenceCase(hullMaterial),
              },
              {
                label: <Trans file="Labels" id="assetLength" />,
                value: length,
              },
              {
                label: <Trans file="Labels" id="assetRegistrationExpiry" />,
                value: moment(expirationDate).format('MM/DD/YYYY'),
              },
            ]}
          />
        </SummaryCard>
      </>
      {error && (
        <TxAlert
          type={TxAlertType.Error}
          heading={<Trans file="Errors" id={'Exception_Link_Title'} />}
        >
          <Trans file="Errors" id={'Exception_Link'} />
        </TxAlert>
      )}
      <FormActions>
        <FormActionsItem>
          <ButtonPrimary
            onClick={handleConfirmClick}
            isLoading={submitting}
            loadingContent={
              <Trans file="Labels" id="Linking" fallback="Linking" />
            }
          >
            <Trans
              file="Labels"
              id="LinkToMyAccount"
              fallback="Link To My Account"
            />
          </ButtonPrimary>
        </FormActionsItem>
        <FormActionsItem>
          <TxButton
            mode={TxButtonMode.Secondary}
            onClick={() => dispatch(goBack())}
            disabled={submitting}
          >
            <Trans file="Labels" id="Back" fallback="Back" />
          </TxButton>
        </FormActionsItem>
      </FormActions>
    </div>
  );
};

TPWDLinkServiceConfirm.propTypes = {
  txNumber: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  hullMaterial: PropTypes.string.isRequired,
  modelYear: PropTypes.string.isRequired,
  make: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
};

export default TPWDLinkServiceConfirm;
