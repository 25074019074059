//@flow

// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './Icon.scss';

// Components
import { PlusIcon, MinusIcon } from './SVGComponents';

type IconProps = {
  name: String,
  className: String,
  onClick: Function,
};

export const icons: { [key: string]: any } = {
  plus: PlusIcon,
  minus: MinusIcon,
};

const Icon = ({ name, className, onClick }: IconProps) => {
  if (!name || !icons[name]) return <></>;

  const Icon = icons[name];

  return (
    <span onClick={onClick} className={className}>
      <Icon role="presentation" />
    </span>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Icon;
