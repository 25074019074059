//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import Modal from '../Modal';
import Trans from '../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './TPWDCantLinkModal.scss';

const TPWDCantLinkModal = () => {
  const dispatch = useDispatch();

  return (
    <Modal name="TPWDCantLinkModal" className="tx-options-modal">
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="TPWDCantLinkModal_Title" />
      </h2>
      <div className="modal-content">
        <div className="modal-list-section">
          <Trans file="Modals" id="TPWDCantLinkModal_List" />
        </div>
        <div className="modal-list-section">
          <div className="external-link-long">
            <Trans file="Modals" id="TPWDCantLinkModal_Info" />
          </div>
        </div>
        <ButtonPrimary
          className="modal-close-button"
          onClick={() => dispatch(closeModal('TPWDCantLinkModal'))}
        >
          <Trans file="Labels" id="okGotIt" />
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

export default TPWDCantLinkModal;
