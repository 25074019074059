import * as BON from './BON';
import * as TDLR from './TDLR';
import * as DMV from './DMV';
import * as DLR from './DPS';
import * as TPWD from './TPWD';
//import * as TCEQ from './TCEQ';

//16 or 452 or 304 is the agency code (agency_CD) from https://fmx.cpa.texas.gov/fm/contacts/agynum/index.php
// slug is the agency name link for the data file??
//27 or TDLR_LR, CPA is the application code(APPLICATION_CD)
//agencyCode:
//slug: str
//appCode: str

export const agencySlugMap = {
  '16': {
    slug: 'bon',
    '27': 'bnlr',
  },
  '452': {
    slug: 'tdlr',
    TDLR_LR: 'tdlrlr',
  },
  '608': {
    slug: 'dmv',
    DMV_RR: 'dmvrr',
  },
  '405': {
    slug: 'dps',
    DLR: 'dpslr',
  },
  '802': {
    slug: 'tpwd',
    TPWD_AS: 'tpwdas',
  },
};

//need add TCEQ obj to Agency data
export const agencyData = { ...BON, ...TDLR, ...DMV, ...DLR, ...TPWD };
