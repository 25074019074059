// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import { TxAgencyLogo } from 'texkit-ui';
import Card from 'texkit/dist/components/Cards/Card';


// Styles
import './agency-card.scss';

const AgencyCard = ({
  agency,
  moreInfoText,
  numLicenses,
  hasLogo = true,
  ...props
}) => {

  const { t } = useTranslation('tpwd');

  return (
    <Card {...props} className="agency-card">
      <>
        {hasLogo && <TxAgencyLogo agency={ agency.name === t('AgencyName', {lng: 'es'}) ? {name: t('AgencyName', {lng: 'en'})} : agency} />}
        <div className="agency-card-text">
          <h3 className="agency-card-title">{agency.name}</h3>
          {moreInfoText && (
            <p>
              {parseInt(numLicenses) > 1 ? numLicenses + ' ' : ''}
              {moreInfoText}
            </p>
          )}
        </div>
      </>
    </Card>
  );
};

AgencyCard.propTypes = {
  moreInfoText: PropTypes.string,
  numLicenses: PropTypes.string,
  hasLogo: PropTypes.bool,
  agency: PropTypes.object.isRequired,
};

export default AgencyCard;
