// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// Components
import { TxAlert } from 'texkit-ui/components';
import Trans from '../../../components/common/Trans/Trans';
// Helpers
import { openModal } from '../../../reducers/modal/modalActions';

const AssetCantLinkAlert = ({ errorType, title, id, assetType }) => {
  const dispatch = useDispatch();

  const assetTypeLower = (assetType || "").toLowerCase();
  return (
    <TxAlert
      type={errorType}
      heading={<Trans file="Errors" id={title} args={[assetTypeLower]} />}
    >
      <Trans file="Errors" id={`${id}_One`} />
      <a
        className="external-link"
        target="_blank"
        onClick={e => {
          e.preventDefault();
          dispatch(openModal('TPWDCantLinkModal'));
        }}
      >
        <Trans file="Errors" id={`${id}_Link`} />
      </a>
      <Trans file="Errors" id={`${id}_Two`} args={[assetTypeLower]} />
    </TxAlert>
  );
};

AssetCantLinkAlert.propTypes = {
  errorType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default AssetCantLinkAlert;
