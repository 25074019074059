// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';

import { selectAgencyToLink } from '../../reducers/agency/linkService/linkServiceActions';
import { selectAllAgenciesAsArray } from '../../reducers/agency/agencySelectors';

// Components
import { Trans } from '../common';

// Texkit Components
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { TxButton, TxButtonMode } from 'texkit-ui/components';
import { FormActions, FormActionsItem } from '../../components';

const Interstitial = ({
  interstitialAgencyCode,
  agencies,
  selectAgencyToLink,
}) => {
  const dispatch = useDispatch();

  const handleAgencySelect = async agency => {
    await selectAgencyToLink(agency, true);
  };

  let selectedInterstitialAgency = {};

  selectedInterstitialAgency = agencies.find(
    value => value.agencySlug === interstitialAgencyCode
  );
  return (
    <>
      <div className="linked-agencies-empty">
        <div className="takeover-page-header">
          <Trans
            file="Interstitial"
            id={`InterstitialHeader_${interstitialAgencyCode.toUpperCase()}`}
            fallabck="Texas Department of Licensing and Regulation"
          />
        </div>
        <h2 className="linked-agencies-empty-title">
          <Trans
            file="Interstitial"
            id={`InterstitialTitle_${interstitialAgencyCode.toUpperCase()}`}
            fallabck="Link TDLR to your account"
          />
        </h2>
        <p>
          <Trans
            file="Interstitial"
            id={`InterstitialDescription_${interstitialAgencyCode.toUpperCase()}`}
            fallback="When you link an item you’ll receive helpful renewal reminders, complete transactions faster and a shortcut to your linked item will show up here for the next time you need it."
          />
        </p>

        <Trans
          file="Interstitial"
          id={`InterstitialSecondaryDescription_${interstitialAgencyCode.toUpperCase()}`}
          fallback="When you link an item you’ll receive helpful renewal reminders, complete transactions faster and a shortcut to your linked item will show up here for the next time you need it."
        />

        <FormActions>
          <FormActionsItem>
            <ButtonPrimary
              onClick={() => handleAgencySelect(selectedInterstitialAgency)}
            >
              <Trans file="Interstitial" id={`InterstitialButton_${interstitialAgencyCode.toUpperCase()}`} fallback="InterstitialButton" />
            </ButtonPrimary>
          </FormActionsItem>
          <FormActionsItem>
            <TxButton
              mode={TxButtonMode.Secondary}
              onClick={() => dispatch(goBack())}
            >
              <Trans file="Labels" id="Back" />
            </TxButton>
          </FormActionsItem>
        </FormActions>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  agencies: selectAllAgenciesAsArray(state),
  interstitialAgencyCode: state.router.location.appSlug,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ selectAgencyToLink }, dispatch);

Interstitial.propTypes = {
  interstitialAgencyCode: PropTypes.string.isRequired,
  selectedAgency: PropTypes.object,
  agencies: PropTypes.arrayOf(PropTypes.object),
  selectAgencyToLink: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Interstitial);
