// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { ButtonSuccessCircle } from 'texkit/dist/components/Buttons';

// Types
import { HeroActionPropType } from '../../../lib/validation/propTypes/heroPropTypes';

const HeroActionsSingle = ({ label, className, ...props }) => (
  <ButtonSuccessCircle
    aria-label="Link Button"
    className={classnames('hero-actions-single', className)}
    type="button"
    {...props}
  >
    <span aria-label="Link" role="button">
      {label}
    </span>
  </ButtonSuccessCircle>
);

HeroActionsSingle.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node])
    .isRequired,
  className: PropTypes.string,
  ...HeroActionPropType,
};

export default HeroActionsSingle;
