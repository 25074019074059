//@flow

// Vendors
import React, { type Node, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

// Components
import { Loading } from '../../../components';

// Actions
import { clearAlertInfo } from '../../../reducers/alert/alertActions';

// Helpers
import { SITE_DATA } from '../../../lib/data';
import { scrollToTop, focusFirstElement } from '../../../lib/helpers';

// Styles
import './page.scss';

// Types
type Props = {
  className?: string,
  isLoading?: boolean,
  children?: Node,
  title?: { id: string, file: string },
};

const PageTitle = ({ title }) => {
  const { t } = useTranslation(title && title.file ? [title.file] : []);
  useEffect(() => {
    const pathArr = window.location.pathname.split('/');
    let pageTitle;
    if (
      pathArr.includes('dmv') ||
      pathArr.includes('dps') ||
      pathArr.includes('tdlr') ||
      pathArr.includes('tpwd')
    ) {
      pageTitle = title
        ? typeof title === 'string'
          ? `${title} | ${SITE_DATA.SITE_NAME_ABBR} `
          : `${t(title.id, title.file)} | ${SITE_DATA.SITE_NAME_ABBR} `
        : SITE_DATA.SITE_NAME_ABBR;
    } else {
      pageTitle = title
        ? typeof title === 'string'
          ? `${SITE_DATA.SITE_NAME_ABBR} - ${title}`
          : `${SITE_DATA.SITE_NAME_ABBR} - ${t(title.id, title.file)}`
        : SITE_DATA.SITE_NAME_ABBR;
    }
    document.title = pageTitle;
  }, [title]);
  return null;
};

const Page = ({ className, isLoading, children, title, ...props }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    scrollToTop();
    focusFirstElement();
    return () => dispatch(clearAlertInfo());
  }, []);

  const UpdateTitle = title
    ? () => <PageTitle title={title} />
    : () => <PageTitle title={''} />;
  return (
    <div {...props} className={classnames('page', className)} role="main">
      {<UpdateTitle />}
      {isLoading ? <Loading /> : children}
    </div>
  );
};

export default Page;
