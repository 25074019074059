//@flow

// Vendors
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Texkit Components
import { TxInput } from 'texkit-ui';
import { Icon } from '../';
import { Trans } from '../';

// Styles
import './QuantityPicker.scss';

type QuantityPickerProps = {
  limit: Number,
  label: String,
  onQuantityChange: Function,
  userChosenItemQuantity: Number,
  userSelectedQuantity: any,
  setUserSelectedQuantity: any,
  disabled: Boolean,
};

const QuantityPicker = ({
  limit,
  label,
  onQuantityChange,
  userChosenItemQuantity,
  userSelectedQuantity,
  setUserSelectedQuantity,
  disabled,
  inputLabel = ''
}: QuantityPickerProps) => {
  useEffect(() => {
    setUserSelectedQuantity(userChosenItemQuantity);
  }, [userChosenItemQuantity]);

  const decrementQuantity = () => {
    setUserSelectedQuantity((previousQuantity: any) => {
      const newQuantity = Math.max(0, previousQuantity - 1);
      if (onQuantityChange) {
        onQuantityChange(newQuantity);
      }
      return newQuantity;
    });
  };

  const incrementQuantity = () => {
    setUserSelectedQuantity((previousQuantity: any) => {
      const newQuantity = Math.min(Number(limit), previousQuantity + 1);
      if (onQuantityChange) {
        onQuantityChange(newQuantity);
      }
      return newQuantity;
    });
  };

  useEffect(() => {
    if (onQuantityChange) {
      onQuantityChange(userSelectedQuantity);
    }
  }, [userSelectedQuantity]);

  return (
    <div className="quantity-picker-wrapper">
      <div className="quantity-picker">
        <div className="switch-container">
          <div
            className="minus-container"
            onClick={!disabled ? decrementQuantity : ''}
          >
            <Icon
              name="minus"
              className={
                userSelectedQuantity === 0 ||
                  userSelectedQuantity === null ||
                  userSelectedQuantity === undefined ||
                  disabled
                  ? 'minus-icon-disabled'
                  : 'minus-icon'
              }
            />
          </div>

          <div
            className="plus-container"
            onClick={!disabled ? incrementQuantity : ''}
          >
            <Icon
              name="plus"
              className={
                userSelectedQuantity === limit ||
                  userSelectedQuantity >= limit ||
                  userSelectedQuantity === null ||
                  userSelectedQuantity === undefined ||
                  disabled
                  ? 'plus-icon-disabled'
                  : 'plus-icon'
              }
            />
          </div>
          <div className="quantity-picker-label">
            <Trans file="Labels" id={label} />
          </div>
          <TxInput
            value={`${userSelectedQuantity}`}
            maxLength={4}
            className="quantity-picker-input"
            placeholder=""
            required
            onChange={e => {
              const inputValue = e.target.value;
              const sanitizedInputValue = inputValue.replace(/[^0-9]/g, '');

              if (Number(sanitizedInputValue) <= limit) {
                setUserSelectedQuantity(Number(sanitizedInputValue));
              }
            }}
            onBlur={e => {
              const inputValue = e.target.value;
              if (inputValue === '') {
                e.target.value = '0';
              }
            }}
            onFocus={e => {
              const inputValue = e.target.value;
              if (inputValue !== '') {
                e.target.value = '';
              }
            }}
            label={inputLabel}
          />
        </div>
        <div className="item-limit">
          <div className="limit-title">
            <Trans file="Labels" id="ItemLimit" fallback="Item limit: " />
          </div>
          <div className="limit">{limit}</div>
        </div>
      </div>
    </div>
  );
};

QuantityPicker.propTypes = {
  limit: PropTypes.number,
  label: PropTypes.string,
  onQuantityChange: PropTypes.func,
  userChosenItemQuantity: PropTypes.number,
  userSelectedQuantity: PropTypes.any,
  setUserSelectedQuantity: PropTypes.any,
  disabled: PropTypes.bool,
};

export default QuantityPicker;
