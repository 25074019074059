export const tpwdas = {
  agencySlug: 'tpwd',
  appCode: 'TPWD_AS',
  slug: 'tpwdas',
  linkService: {
    linkType: 'registration',
    form: {
      labels: {
        txNumber: { id: 'TXNumber' },
        dob: { id: 'PrimaryOwner_DOB' },
        lastName: { id: 'PrimaryOwner_LastName' },
      },
    },
  },
};
