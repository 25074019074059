/* eslint-disable react/prop-types */
import React from 'react';

const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgComponent;
