import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';

//components
import { Trans, FormActions, FormActionsItem } from '../../../components';
import { TxButton, TxButtonMode } from 'texkit-ui';

// Helpers
import { toSentenceCase } from '../../../lib/helpers';

const TPWDAlreadyLinkedSummary = ({
  txNumber,
  assetType = '',
  modelYear,
  make,
  hullMaterial,
  length,
  expirationDate,
}) => {
  const dispatch = useDispatch();
  const assetTypeLowercase = (assetType || '').toLowerCase();
  const assetTypeSentence = toSentenceCase(assetType);
  return (
    <>
      <h1 className="h2">
        <Trans
          file="tpwd"
          id="tpwdas_LinkService_ConfirmTitleLinked"
          args={[assetTypeSentence]}
        />
      </h1>
      <p>
        <Trans
          file="tpwd"
          id="tpwdas_LinkService_ConfirmDescriptionLinked"
          args={[assetTypeLowercase]}
        />
      </p>
      <SummaryCard>
        <SummaryCard.List
          items={[
            {
              className: 'word-break',
              label: <Trans file="Labels" id="txNumber" />,
              value: txNumber,
            },
            {
              label: <Trans file="Labels" id="assetType" />,
              value: toSentenceCase(assetType),
            },
            {
              label: <Trans file="Labels" id="assetModelYear" />,
              value: modelYear,
            },
            {
              label: <Trans file="Labels" id="assetMake" />,
              value: make,
            },
            {
              label: <Trans file="Labels" id="assetHullType" />,
              value: toSentenceCase(hullMaterial),
            },
            {
              label: <Trans file="Labels" id="assetLength" />,
              value: length,
            },
            {
              label: <Trans file="Labels" id="assetRegistrationExpiry" />,
              value: moment(expirationDate).format('MM/DD/YYYY'),
            },
          ]}
        />
      </SummaryCard>
      <FormActions>
        <FormActionsItem>
          <ButtonPrimary onClick={() => dispatch(push('/agency/tpwd'))}>
            <Trans
              file="tpwd"
              id="tpwdas_ViewAsset"
              args={[assetTypeLowercase]}
            />
          </ButtonPrimary>
        </FormActionsItem>
        <FormActionsItem>
          <TxButton
            mode={TxButtonMode.Secondary}
            onClick={() => dispatch(push(`/link/lookup/tpwdas`))}
          >
            <Trans file="tpwd" id={`tpwdas_LinkDifferent`} />
          </TxButton>
        </FormActionsItem>
      </FormActions>
    </>
  );
};

TPWDAlreadyLinkedSummary.propTypes = {
  txNumber: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  modelYear: PropTypes.string.isRequired,
  make: PropTypes.string.isRequired,
  hullMaterial: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
};

export default TPWDAlreadyLinkedSummary;
