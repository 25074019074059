// Helpers
import { to } from '../../lib/helpers';

// Requests
import { lookupAppLinkRequest } from '../../backend/requests';

// Actions
import { getMyApps } from '../agency/agencyActions';

// Selectors
import { selectAppBasicInfo } from './appSelectors';

// Consts
export const APP_TRANSACTION_IDS = {
  BASIC_INFO: 'BASICINFO',
  FULL_PROFILE: 'FULLPROFILE',
  RENEWAL_APP_LOOKUP: 'RENEWAL_APP_LOOKUP',
  SUBMIT_RENEWAL_REQUEST: 'SUBMIT_RENEWAL_REQUEST',
  SUBMIT_CREATE_PAYMENT: 'SUBMIT_CREATE_PAYMENT',
  SUBMIT_RENEWAL_PAYMENT: 'SUBMIT_RENEWAL_PAYMENT',
  SUBMIT_UPGRADE_RENEWAL_PAYMENT: 'SUBMIT_UPGRADE_RENEWAL_PAYMENT',
  ELIGIBILITY_LOOKUP: 'ELIGIBILITYLOOKUP',
  SUBMIT_UPGRADE_PAYMENT: 'SUBMIT_UPGRADE_PAYMENT',
  STICKER_TRACKER: 'STICKERTRACKER',
  FEE_CALCULATION: 'FEECALCULATION',
  RENEWAL: 'RENEWAL',
  UPGRADE: 'UPGRADE',
  UPGRADE_AND_RENEW: 'UPGRADEANDRENEW',
  EMERGENCY_CONTACT_LOOKUP: 'EMERGENCY_CONTACT_LOOKUP',
  EMERGENCY_CONTACT_TRANSACT: 'EMERGENCY_CONTACT_TRANSACT',
  REPLACEMENT: 'REPLACEMENT',
  ERNOUPDATE: 'ERNOUPDATE',
};
export const APP_STORE_BASIC_INFO = '[APP] Store Basic Info';
export const APP_CLEAR_BASIC_INFO = '[APP] Clear Basic Info';
export const APP_STORE_FULL_PROFILE = '[APP] Store Full Profile';
export const APP_CLEAR_FULL_PROFILE = '[APP] Clear Full Profile';
export const APP_CLEAR_APP_DATA = '[APP] Clear App Data';
export const APP_SET_DASHBOARD_HISTORY_TAB = '[APP] Set Dashboard History Tab';

export const getBasicInfo = userKey => async (dispatch, getState) => {
  const { linkUserKeys } = getState().agency;

  if (!linkUserKeys || linkUserKeys.length < 1) await dispatch(getMyApps());

  const { links } = getState().agency;

  let link = (links || {})[userKey];

  if (!link) {
    dispatch(clearBasicInfo());
    return Promise.reject();
  }

  if (link.renewalIndicator) {
    dispatch(storeBasicInfo(link));
    return Promise.resolve(link);
  }

  const requestBody = {
    appId: link.appId,
    userKey,
    transactionId: APP_TRANSACTION_IDS.BASIC_INFO,
  };

  const [{ body }] = await to(dispatch(lookupAppLinkRequest(requestBody)));

  link = { ...link, ...body.data };

  dispatch(storeBasicInfo(link));
  return Promise.resolve(link);
};

export const getFullProfile = (
  transactionId = APP_TRANSACTION_IDS.FULL_PROFILE
) => async (dispatch, getState) => {
  const basicInfo = selectAppBasicInfo(getState());

  if (!basicInfo) return Promise.reject();

  const requestBody = {
    appId: basicInfo.appId,
    userKey: basicInfo.userKey,
    transactionId: transactionId,
    additionalParams: [],
  };

  if (transactionId === APP_TRANSACTION_IDS.RENEWAL_APP_LOOKUP)
    requestBody.additionalParams.push({
      name: 'renewalApplicationId',
      value: basicInfo.selectedLicense.licenseData.renewalApplicationId,
    });

  // TODO: Error handling
  const [{ body }] = await to(dispatch(lookupAppLinkRequest(requestBody)));

  dispatch(storeFullProfile(body.data));

  return Promise.resolve(body.data);
};

export const storeBasicInfo = payload => dispatch =>
  dispatch({ type: APP_STORE_BASIC_INFO, payload });

export const clearBasicInfo = () => dispatch =>
  dispatch({ type: APP_CLEAR_BASIC_INFO });

export const storeFullProfile = payload => dispatch =>
  dispatch({ type: APP_STORE_FULL_PROFILE, payload });

export const clearFullProfile = () => dispatch =>
  dispatch({ type: APP_CLEAR_FULL_PROFILE });

export const clearAppData = () => dispatch =>
  dispatch({ type: APP_CLEAR_APP_DATA });

export function setDashboadHistoryTab(tab) {
  return {
    type: APP_SET_DASHBOARD_HISTORY_TAB,
    payload: tab,
  };
}
