//@flow

// Vendors
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import Modal from '../Modal';
import { FormActions, FormActionsItem } from '../..';
import ServiceIconText from '../../ServiceIconText/ServiceIconText';
import Trans from '../../Trans/Trans';
import { TxButton, TxButtonMode, TxLoadingText } from 'texkit-ui/components';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';
import {
  pushMessageBanner,
  setAutoDismissMessageBannerTimer,
} from '../../../../reducers/messageBanner/messageBannerActions';
import {
  unlinkAppLink,
  removeLinkFromState,
} from '../../../../reducers/agency/agencyActions';

// Helpers
import { selectModalData } from '../../../../reducers/modal/modalSelectors';

// Styles
import './TPWDAutoUnlink.scss';

// Switch to adjusted SCSS if on iOS device
var userAgent = window.navigator.userAgent.toLowerCase(),
  ios = /iphone|ipod|ipad/.test(userAgent);

const TPWDAutoUnlink = () => {
  const tpwdLinks = (useSelector(selectModalData) || {})['tpwdLinks'] || [];
  const moreAssets =
    (useSelector(selectModalData) || {})['moreAssets'] || false;
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(closeModal('TPWDAutoUnlink'));
    };
  }, []);

  const handleRemoveLinkClick = async () => {
    setSubmitting(true);

    const successBanner = tpwdLinks.length > 1 ? `tpwd_NoMoreAgencyNotificationsMessageBannerMulti` :`tpwd_NoMoreAgencyNotificationsMessageBanner`;
    const errorBanner = tpwdLinks.length > 1 ? 'tpwd_cantUnlinkMulti' : 'tpwd_cantUnlink';

    try {
      const unlinkPromises = tpwdLinks.map((link) => {
          const assetTypeLC = (link.mismatchAssetType || '').toLowerCase();
          const userKey = { userKey: link.userKey };

          return dispatch(unlinkAppLink(userKey, '', true)).then((response) => {
            if(response.body.status === 'SUCCESS'){
              dispatch(removeLinkFromState(link));
            }
            return response;
          })
      });

      const results = await Promise.all(unlinkPromises);
      console.log(results);

      const hasSuccess = results.some(response => response.body.status === "SUCCESS");
      const hasError = results.some(response => response.body.status === "ERROR");
      if(hasSuccess) {
        const messageIdTime = Date.now();
        dispatch(
          pushMessageBanner([
              {
                messageId: messageIdTime.toString(),
                textEnglish: `{"type": "Modals", "id": "${successBanner}"}`,
                textSpanish: `{"type": "Modals", "id": "${successBanner}"}`,
                startTime: '2021-01-01 18:35:00.0',
                endTime: '2040-06-30 12:05:00.0',
                priority: '4',
                status: 'R',
                author: 'USER',
                valid: true,
                dismissable: true,
              },
            ])
        )
              dispatch(closeModal('TPWDAutoUnlink'));
              setAutoDismissMessageBannerTimer(messageIdTime);
              setTimeout(() => {
                 dispatch(push('/'));
              }, 200);
              setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }, 300);
        }
        if(hasError) {
          const messageIdTime = Date.now();
          dispatch(
            pushMessageBanner([
              {
                messageId: messageIdTime.toString(),
                textEnglish: `{"type": "Modals", "id": "${errorBanner}"}`,
                textSpanish: `{"type": "Modals", "id": "${errorBanner}"}`,
                startTime: '2021-01-01 18:35:00.0',
                endTime: '2040-06-30 12:05:00.0',
                priority: '1',
                status: 'R',
                author: 'USER',
                valid: true,
                dismissable: true,
              },
            ]));
            dispatch(closeModal('TPWDAutoUnlink'));
            setTimeout(() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 300);
        }
        setSubmitting(false);
      }
       catch(error) {
          const messageIdTime = Date.now();
          dispatch(
            pushMessageBanner([
              {
                messageId: messageIdTime.toString(),
                textEnglish: `{"type": "Modals", "id": "${errorBanner}"}`,
                textSpanish: `{"type": "Modals", "id": "${errorBanner}"}`,
                startTime: '2021-01-01 18:35:00.0',
                endTime: '2040-06-30 12:05:00.0',
                priority: '1',
                status: 'R',
                author: 'USER',
                valid: true,
                dismissable: true,
              },
            ])
        );
          setSubmitting(false);
          dispatch(closeModal('TPWDAutoUnlink'));
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 300);
    }
  };
  return (
    <Modal
      name="TPWDAutoUnlink"
      disableCloseButton="true"
      disableOnClickOutside="true"
      className="tpwd-autounlink-modal"
    >
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="TPWDAutoUnlink_Title" />
      </h2>
      <div className="modal-content">
        <p className="modal-subtitle auto-unlink-txNumber">
          {tpwdLinks.map(link => {
            return (
              // eslint-disable-next-line react/jsx-key
              <ServiceIconText
                className="agency-linked-services-item-icon-text flex-item"
                type={ios ? `boat-plate-ios` : `boat-plate`}
                text={`TX ${link.mismatchAsset.substring(0, 4) +
                  ' ' +
                  link.mismatchAsset.substring(4)}`}
              />
            );
          })}
        </p>
        <p className="modal-subtitle auto-unlink-subtitle-last">
          <Trans file="Modals" id="TPWDAutoUnlink_Desc" />
        </p>
        {!moreAssets ? (
          <p className="modal-subtitle auto-unlink-subtitle-last">
            <Trans file="Modals" id="TPWDAutoUnlinkNoMoreAssets_Desc" />
          </p>
        ) : null}
        <FormActions align="center">
          <FormActionsItem>
            <TxButton
              className="ButtonDanger"
              onClick={handleRemoveLinkClick}
              mode={TxButtonMode.Danger}
            >
              {isSubmitting ? (
                <TxLoadingText>
                  <Trans file="Labels" id="Unlinking" fallback="Unlinking" />
                </TxLoadingText>
              ) : (
                <Trans file="Labels" id="tpwd_unlink" />
              )}
            </TxButton>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
};

export default TPWDAutoUnlink;
