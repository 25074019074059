// Vendor
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formikInjectedPropsTypes } from '../../../lib/validation/propTypes/formikPropTypes';
import classnames from 'classnames';
// Components
import { Trans, CreditCardIcon, InputZipCode } from '../..';
import { TextButton } from 'texkit/dist/components/Buttons';

// Helpers
import { paymentAccountsData } from '../../../lib/data';
import { publicURL } from '../../../lib/helpers';

const PaymentCard = ({
  className,
  paymentAccount,
  showCvv,
  formikProps,
  showEditButton,
  isCardExpired,
  onEdit = () => { },
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { paymentAccountType, cardNumber, expirationDate } = paymentAccount;
  let fontStyle;

  if (showCvv) {
    fontStyle = className = 'payment-card-selected';
  } else {
    fontStyle = className = 'payment-card-name';
  }

  const onHover = () => {
    setPopoverOpen(true);
  };

  const onHoverLeave = () => {
    setPopoverOpen(false);
  };

  const dangerTextColor = isCardExpired ? 'text-danger' : '';

  return (
    <>
      <div className={classnames('payment-card', className)}>
        <CreditCardIcon
          name={
            paymentAccountType && paymentAccountsData[paymentAccountType]
              ? paymentAccountsData[paymentAccountType].icon
              : ''
          }
        />
        <div className="payment-card-info">
          <div className="payment-card-column">
            <div
              className={`${fontStyle} payment-card-number ${dangerTextColor}`}
            >
              *&nbsp;*&nbsp;*&nbsp;&nbsp;{cardNumber}
            </div>
            <span
              className={`${fontStyle} payment-card-name ${dangerTextColor}`}
            >
              {paymentAccountType && paymentAccountsData[paymentAccountType] ? (
                <Trans file="Payment" id={paymentAccountType} />
              ) : (
                ''
              )}
            </span>
            <span
              className={`${fontStyle} payment-card-expiration ${dangerTextColor}`}
            >
              <Trans file="Todo" id="TodoListState_Todo" fallback="Expired" />{' '}
              {expirationDate}
            </span>
            {showEditButton && (
              <TextButton type="button" onClick={() => onEdit(paymentAccount)}>
                <Trans
                  file="Payment"
                  id="UpdateCardInformation"
                  fallback="Update card information"
                />
              </TextButton>
            )}
          </div>
        </div>
      </div>
      {showCvv && (
        <>
          <InputZipCode
            className="payment-card-security-code"
            name="cvv"
            label={<Trans file="Payment" id="SecurityCode" />}
            labelPosition="above"
            minlength="3"
            formikProps={formikProps}
            required={true}
            maxlength="4"
          />
          <a onMouseEnter={onHover} onMouseLeave={onHoverLeave}>
            <Trans file="Payment" id="WhatIsThis" />
          </a>
          {popoverOpen && (
            <div className="popover top">
              <div className="arrow"></div>
              <div className="popover-content">
                <div>
                  <img
                    src={publicURL('visaMastercardDiscover.png')}
                    className="popover-img pull-left"
                    height="175"
                    width="256"
                  />
                  <img
                    src={publicURL('americanExpress.png')}
                    className="popover-img pull-left"
                    height="175"
                    width="254"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

PaymentCard.propTypes = {
  className: PropTypes.string,
  paymentAccount: PropTypes.shape({
    paymentAccountId: PropTypes.string.isRequired,
    paymentAccountType: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    expirationDate: PropTypes.string.isRequired,
  }),
  showCvv: PropTypes.bool.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
  showEditButton: PropTypes.bool,
  onEdit: PropTypes.func,
  isCardExpired: PropTypes.bool,
};

export default PaymentCard;
