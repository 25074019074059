//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import Modal from '../Modal';
import Trans from '../../Trans/Trans';
import { ButtonPrimary, TextButton } from 'texkit/dist/components/Buttons';
import { ServiceIcon } from '../../../';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './TPWDOptionsModal.scss';

const TPWDOptionsModal = () => {
  const dispatch = useDispatch();

  return (
    <Modal name="TPWDOptionsModal" className="tx-options-modal">
      <h2 className="h1 modal-title">
        <Trans
          file="Modals"
          id="TPWDOptionsModal_Title"
          fallback="Learn more about services for your outboard motor"
        />
      </h2>
      <div className="modal-content">
        <p className="modal-paragraph">
          <Trans
            file="Modals"
            id="TPWDOptionsModal_Description"
            fallback="TxT does not support transactions for outboard motors at this time. You can access services for outboard motors online or in-person by completing any of the following:"
          />
        </p>
        <div className="modal-list-section">
          <Trans
            file="Modals"
            id="TPWDOptionsModal_Online_Subtitle"
            fallback="Online"
          />
          <ul>
            <li>
              <Trans file="Modals" id="TPWDOptionsModal_Online_One" />
              <TextButton className="external-link">
                <Trans
                  file="Modals"
                  id="TPWDOptionsModal_Online_Link"
                  fallback="legacy TPWD online system"
                />
                <ServiceIcon type="external-link" />
              </TextButton>
              <Trans file="Modals" id="TPWDOptionsModal_Online_Two" />
            </li>
          </ul>
        </div>
        <div className="modal-list-section">
          <Trans
            file="Modals"
            id="TPWDOptionsModal_ByMail_Subtitle"
            fallback="By Mail"
          />
          <ul>
            <li>
              <Trans file="Modals" id="TPWDOptionsModal_ByMail_One" />
              <TextButton className="external-link">
                <Trans
                  file="Modals"
                  id="TPWDOptionsModal_ByMail_Link"
                  fallback="legacy TPWD online system"
                />
                <ServiceIcon type="external-link" />
              </TextButton>
              <Trans file="Modals" id="TPWDOptionsModal_ByMail_Two" />
            </li>
          </ul>
        </div>
        <div className="modal-list-section">
          <Trans
            file="Modals"
            id="TPWDOptionsModal_InPerson_Subtitle"
            fallback="In-Person"
          />
          <ul>
            <li>
              <Trans file="Modals" id="TPWDOptionsModal_InPerson_One" />
              <TextButton className="external-link">
                <Trans
                  file="Modals"
                  id="TPWDOptionsModal_InPerson_Link_One"
                  fallback="(PWD 144-M)"
                />
                <ServiceIcon type="external-link" />
              </TextButton>
              <span className="external-link-long">
                <Trans file="Modals" id="TPWDOptionsModal_InPerson_Two" />
                <Trans file="Modals" id="TPWDOptionsModal_InPerson_Three" />
              </span>
            </li>
          </ul>
        </div>
        <ButtonPrimary
          className="modal-close-button"
          onClick={() => dispatch(closeModal('TPWDOptionsModal'))}
        >
          <Trans file="Labels" id="okGotIt" />
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

export default TPWDOptionsModal;
