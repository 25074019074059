// Vendors
import * as yup from 'yup';
import { DateTime } from 'luxon';

export const tpwd = {
  APP_RULES: {
    tpwdas: {
      linkService: {
        txNumber: yup
          .string()
          .trim()
          .matches(/^\d{4}[A-Za-z][A-Za-z]/, { id: 'Field_TxNumberRequired' })
          .required({ id: 'Field_TxNumberRequired' }),
        dob: yup
          .string()
          .matches(/^\d\d\/\d\d\/\d\d\d\d/, { id: 'Field_DOBRequired_TPWD' })
          .test('minDate', { id: 'Field_DOBMax_TPWD' }, value => {
            if (value && value.length === 10) {
              const dateEntered = DateTime.fromFormat(value, 'MM/dd/yyyy');
              const dateMin = DateTime.local().minus({ years: 100 });
              return dateEntered.diff(dateMin, 'years').values.years > 0;
            }
            return true;
          })
          .test('maxDate', { id: 'Field_DOBMin_TPWD' }, value => {
            if (value && value.length === 10) {
              const dateEntered = DateTime.fromFormat(value, 'MM/dd/yyyy');
              const dateMax = DateTime.local().minus({ days: 1 });
              return dateEntered.diff(dateMax, 'days').values.days < 0;
            }
            return true;
          })
          .required({ id: 'Field_DOBRequired_TPWD' }),
        lastName: yup
          .string()
          .trim()
          .required({ id: 'Field_LastNameRequired' }),
      },
    },
  },
};
